export default {
  namespaced: true,
  state: {
    theme: {
      colors: {
        primary: 'blue-grey'
      }
    }
  }
};
