import Vue from 'vue';
import App from './App.vue';
import router from './router.js';
import store from './store/index.js';
import vuetify from './plugins/vuetify.js';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Notifications from 'vue-notification';
import Notification from './plugins/notification.js';
import veevalidate from './plugins/vee-validate.js';

Promise.try = function (fn) {
  return new Promise(resolve => resolve(fn()));
};

Vue.config.productionTip = false;

Vue.use(Notifications);
Vue.use(VueAxios, axios);
Vue.use(Notification);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

router.beforeEach((to, from, next) => {
  return store.getters['auth/isGuest'] && to.matched.some(route => route.meta.auth === true)
    ? next({ name: 'login' })
    : next();
});

store.dispatch('auth/loginFromToken')
  .then(isAuthenticated => isAuthenticated || Promise.reject(new Error('Unable to retrieve user.')))
  .catch(e => {
    if (router.currentRoute.matched.some(route => route.meta.auth === true)) {
      return router.push({ name: 'login' });
    }
  });

store.subscribe((mutation, state) => {
  if (mutation.type === 'auth/setUser' && router.currentRoute.name === 'login') {
    router.push({ name: 'dashboard' });
  } else if (mutation.type === 'auth/logout') {
    router.push({ name: 'login' });
  }
});

Vue.component('ValidationProvider', veevalidate.ValidationProvider);
Vue.component('ValidationObserver', veevalidate.ValidationObserver);

new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app');
