import jwt from 'jwt-decode';
import Vue from 'vue';

const AUTH_TOKEN_KEY = 'auth_token';

const extractTokenData = (refreshToken) => {
  const data = jwt(refreshToken);

  if (data.hasOwnProperty('exp') && data.exp < new Date().getTime() / 1000) {
    throw new Error('Token expired.');
  }

  return data;
};

const setAxiosToken = (token) => {
  Vue.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const removeAxiosToken = () => {
  delete Vue.axios.defaults.headers.common.Authorization;
};

export default {
  namespaced: true,
  state: {
    formErrors: null,
    refreshToken: localStorage.getItem(AUTH_TOKEN_KEY) || '',
    user: null
  },
  getters: {
    isGuest: state => !state.refreshToken
  },
  mutations: {
    setFormErrors: (state, errors) => {
      state.formErrors = errors;
    },
    setUser: (state, user) => {
      state.user = user;
    },
    setRefreshToken: (state, refreshToken) => {
      extractTokenData(refreshToken);

      state.refreshToken = refreshToken;
    },
    logout: (state) => {
      state.refreshToken = '';
      state.user = null;
    }
  },
  actions: {
    logout: ({ commit }) => {
      localStorage.removeItem(AUTH_TOKEN_KEY);

      removeAxiosToken();

      commit('logout');
    },
    login: ({ commit, dispatch }, { email, password }) => {
      return Vue.axios.post('/auth/login', { email, password })
        .then(res => {
          commit('setRefreshToken', res.data.access_token);
          localStorage.setItem(AUTH_TOKEN_KEY, res.data.access_token);

          setAxiosToken(res.data.access_token);

          return dispatch('getUser');
        })
        .catch(e => {
          if (e.hasOwnProperty('response')) {
            if (e.response.status === 422) {
              commit('setFormErrors', e.response.data.errors);
            } else {
              return Promise.reject(new Error(e.response.data.error || e.response.data.message));
            }
          }
          return Promise.reject(e.message);
        });
    },
    loginFromToken: ({ state, commit, dispatch }) => {
      return Promise.try(() => {
        if (state.refreshToken) {
          extractTokenData(state.refreshToken);

          setAxiosToken(state.refreshToken);

          return dispatch('getUser');
        }
      })
        .catch(e => {
          dispatch('logout');

          return Promise.reject(e);
        });
    },
    getUser: ({ commit, state }) => {
      return Vue.axios.post('/auth/me')
        .then(res => {
          commit('setUser', res.data.data);

          return state.user;
        });
    }
  }
};
