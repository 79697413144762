import ro from 'vee-validate/dist/locale/ro.json';
import { required, email, confirmed, ext, mimes, size } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate';
// Import rules
extend('confirmed', confirmed);
extend('email', email);
extend('ext', ext);
extend('mimes', mimes);
extend('required', required);
extend('size', size);
// Activate romanian language and translate fields
localize('ro', ro);
localize({
  ro: {
    names: {
      email: 'Adresa de e-mail',
      file: 'Fisier',
      institution: 'Institutie',
      labelsType: 'Tip etichete',
      name: 'Nume',
      password: 'Parola',
      repeat_password: 'Repeta parola',
      role: 'Rol',
      uic: 'CUI'
    }
  }
});

export default {
  ValidationProvider,
  ValidationObserver
};
