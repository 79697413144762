import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/public/site/institutions/:institutionId',
      props: to => ({
        institutionId: parseInt(to.params.institutionId)
      }),
      component: () => import(/* webpackChunkName: "public" */ './views/layouts/public/site.vue'),
      children: [
        {
          path: '/',
          name: 'public.site.index',
          props: true,
          component: () => import(/* webpackChunkName: "public" */ './views/public/site/index.vue')
        },
        {
          path: 'label/:labelId',
          props: true,
          component: () => import(/* webpackChunkName: "public" */ './views/public/site/label.vue'),
          name: 'public.site.label'
        }
      ]
    },
    {
      path: '/public',
      component: () => import(/* webpackChunkName: "public" */ './views/layouts/public/iframe.vue'),
      children: [
        {
          path: 'institutions/:institutionId',
          props: to => ({
            institutionId: parseInt(to.params.institutionId)
          }),
          component: () => import(/* webpackChunkName: "public" */ './views/public/iframe/institution/index.vue'),
          children: [
            {
              path: '/',
              props: to => ({
                institutionId: parseInt(to.params.institutionId)
              }),
              component: () => import(/* webpackChunkName: "public" */ './views/public/iframe/institution/institution.vue')
            }
          ]
        },
        {
          path: 'institutions/:institutionId/labels/:labelId',
          props: to => ({
            institutionId: parseInt(to.params.institutionId),
            labelId: parseInt(to.params.labelId)
          }),
          component: () => import(/* webpackChunkName: "public" */ './views/public/iframe/label.vue')
        }
      ]
    },
    {
      path: '/',
      meta: {
        auth: true
      },
      component: () => import(/* webpackChunkName: "admin" */ './views/layouts/base.vue'),
      children: [
        {
          path: '/',
          name: 'dashboard',
          component: () => import(/* webpackChunkName: "admin" */ './views/dashboard.vue')
        },
        {
          path: 'logs',
          name: 'logs',
          component: () => import(/* webpackChunkName: "admin" */ './views/logs.vue')
        },
        {
          path: '/users',
          component: () => import(/* webpackChunkName: "users" */ './views/users/index.vue'),
          children: [
            {
              path: '',
              name: 'users.index',
              component: () => import(/* webpackChunkName: "users" */ './views/users/list.vue')
            },
            {
              path: 'create',
              name: 'users.create',
              component: () => import(/* webpackChunkName: "users" */ './views/users/create.vue')
            },
            {
              path: ':id/edit',
              name: 'users.edit',
              props: to => ({
                id: parseInt(to.params.id)
              }),
              component: () => import(/* webpackChunkName: "users" */ './views/users/edit.vue')
            }
          ]
        },
        {
          path: '/institutions',
          component: () => import(/* webpackChunkName: "users" */ './views/institutions/index.vue'),
          children: [
            {
              path: '',
              name: 'institutions.index',
              component: () => import(/* webpackChunkName: "users" */ './views/institutions/list.vue')
            },
            {
              path: 'create',
              name: 'institutions.create',
              component: () => import(/* webpackChunkName: "users" */ './views/institutions/create.vue')
            },
            {
              path: ':id/edit',
              name: 'institutions.edit',
              props: to => ({
                id: parseInt(to.params.id)
              }),
              component: () => import(/* webpackChunkName: "users" */ './views/institutions/edit.vue')
            }
          ]
        },
        {
          path: '/labels',
          component: () => import(/* webpackChunkName: "labels" */ './views/labels/index.vue'),
          children: [
            {
              path: ':id?',
              name: 'labels.index',
              props: to => ({
                id: to.params.hasOwnProperty('id') ? parseInt(to.params.id) : null
              }),
              component: () => import(/* webpackChunkName: "labels" */ './views/labels/labels.vue'),
              children: [
                {
                  path: 'files',
                  name: 'labels.files',
                  component: () => import(/* webpackChunkName: "labels" */ './views/labels/files.vue')
                }
              ]
            }
          ]
        },
        {
          path: 'files',
          component: () => import(/* webpackChunkName: "files" */ './views/labels/files/index.vue'),
          children: [
            {
              path: '',
              name: 'files.create',
              component: () => import(/* webpackChunkName: "files" */ './views/labels/files/create.vue')
            },
            {
              path: ':id',
              name: 'files.edit',
              props: to => ({
                id: parseInt(to.params.id)
              }),
              component: () => import(/* webpackChunkName: "files" */ './views/labels/files/edit.vue')
            }
          ]
        }
      ]
    },
    {
      path: '/login',
      component: () => import(/* webpackChunkName: "public" */ './views/layouts/login.vue'),
      children: [
        {
          path: '',
          name: 'login',
          component: () => import(/* webpackChunkName: "public" */ './views/login.vue')
        }
      ]
    },
    {
      path: '*',
      component: () => import(/* webpackChunkName: "public" */ './views/layouts/public/iframe.vue'),
      children: [
        {
          path: '',
          component: {
            render (h) {
              return h('div', {}, [
                h('h1', 'Pagina negasita.'),
                h('pre', this.$route.fullPath)
              ]);
            }
          }
        }
      ]
    }
  ]
});
