export default {
  install (Vue, options) {
    Vue.prototype.$success = function (text) {
      Vue.prototype.$notify({
        title: text || '',
        type: 'success'
      });
    };

    Vue.prototype.$error = function (e) {
      const error = e.response && e.response.status !== 422 ? e.response.data.message : e.message;

      Vue.prototype.$notify({
        title: error,
        type: 'error'
      });
    };
  }
};
