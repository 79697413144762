import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/auth.js';
import config from '@/store/config.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    navigation: null
  },
  getters: {
    isLoading: state => state.loading
  },
  mutations: {
    pageLoading: (state, v) => {
      state.loading = !!v;
    },
    changeNavigationTo: (state, route) => {
      state.navigation = route;
    }
  },
  modules: {
    auth,
    config
  }
});
